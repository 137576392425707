<template>
  <SelaFieldError v-slot="{ hasError }" :name="error" class="mb-3">
    <label>
      <span
        class="text-sm font-semibold text-sela-light"
        :class="{ 'text-red-400': hasError }"
      >
        {{ label }}
      </span>
    </label>
    <div class="relative mt-2">
      <input
        class="sela-text-field"
        :class="[{ error: hasError },inputClass]"
        v-bind="$attrs"
        :value="modelValue"
        :type="inputType"
        @input="updateValue"
      />
      <button
        v-if="inputType == 'password'"
        tabindex="-1"
        class="absolute -translate-y-1/2 cursor-pointer ltr:left-2 rtl:right-2 top-1/2 w-4 h-4 text-sela-light"
        @click="showPass = !showPass"
      >
        <div v-if="type === 'password'">
          <v-icon v-if="showPass" icon="mdi mdi-eye" :size="16" class="block" />
          <v-icon v-else icon="mdi mdi-eye-off" :size="16" class="block" />
        </div>
      </button>
    </div>
  </SelaFieldError>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label: string
    modelValue: string
    type: string
    inputClass?: string
    error?: string | undefined
  }>(),
  {
    label: '',
    type: 'text',
    inputClass: 'ltr:text-right rtl:text-left',
    error: undefined
  }
)

const emit = defineEmits(['update:modelValue'])

const showPass = ref(false)

const inputType = computed(() => {
  return showPass.value ? 'text' : props.type
})

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}
</script>
